import type { FC } from "react";

const getConfig = (): any => {
  if (typeof window !== "undefined") {
    return (window as any)?.ENV_VARS;
  } else if (typeof process !== "undefined") {
    return process.env;
  }
};


/**
 * Only the variable with prefix = @prefix will pass to client-side. (public variable)
 */

export const EnvironmentConfig: FC<{ prefix?: string }> = ({ prefix = "REMIX_PUBLIC_" }) => {
  const vars = typeof process === "undefined" ? {} : Object.keys(process.env as object).reduce((_env: any, key: string) => {
    if (key.startsWith(prefix)) {
      _env[key] = process.env[key];
    }
    return _env
  }, {});

  return (
    <>
      {typeof process !== "undefined" && (
        <script
          dangerouslySetInnerHTML={{
            __html: `window.ENV_VARS = ${JSON.stringify(vars)}`
          }}

        ></script>
      )
      }
    </>
  );
};


export default getConfig;
